import { z } from 'zod';

import { currencyCodeEnum } from '@yonderlabs-packages/schemas-currency';
import { healthProviderEnum } from '@yonderlabs-packages/schemas-health-providers';
import { lifeProviderEnum } from '@yonderlabs-packages/schemas-life-providers';
import { pensionProviderEnum } from '@yonderlabs-packages/schemas-pension-providers';

import { apiResponseFailureSchemaWithErrorArray, apiResponseSuccessSchema } from '../base/apiResponse';

export const getBillingInformationReportErrorEnum = z.enum(['UnknownError', 'UnknownBillingId', 'None']);

export const getBillingInformationReportPathSchema = z.object({
  billingInformationId: z.string().uuid(),
});

const benefitTypeEnum = z.enum(['health', 'pension', 'life']);
const benefitScopeEnum = z.enum(['group', 'personal']);

const benefitScopeTypeSchema = z.object({
  scope: benefitScopeEnum,
  type: benefitTypeEnum,
});

const coverTransactionState = z.enum(['Processing', 'Paid', 'Retrying']);

const benefitReportSchema = z.object({
  transactionId: z.string().uuid(),
  benefitId: z.string().uuid(),
  benefit: benefitScopeTypeSchema,
  state: coverTransactionState,
  currencyCode: currencyCodeEnum,
});

const individualTransactionSchema = z.object({
  peopleId: z.string().uuid(),
  memberId: z.string().uuid(),
  employeeName: z.string().nullable(),
});

const individualHealthTransactionSchema = individualTransactionSchema.extend({
  organizationGrossContribution: z.number(),
  organizationNetContribution: z.number(),
  peopleNetContribution: z.number(),
  insurancePremiumTax: z.number(),
  netPremium: z.number(),
  dependants: z.number(),
});

export type IndividualHealthTransactionReport = z.infer<typeof individualHealthTransactionSchema>;

export const healthReportSchema = benefitReportSchema.extend({
  provider: healthProviderEnum,
  organizationGrossContribution: z.number(),
  organizationNetContribution: z.number(),
  peopleNetContribution: z.number(),
  insurancePremiumTax: z.number(),
  netPremium: z.number(),
  individualTransactions: z.array(individualHealthTransactionSchema),
});

export type HealthReport = z.infer<typeof healthReportSchema>;

const individualPensionTransactionSchema = individualTransactionSchema.extend({
  organizationContribution: z.number(),
  personalContribution: z.number(),
  totalContribution: z.number(),
  ofWhichAvc: z.number(),
  organizationContributionPercentage: z.number().min(0).max(100),
  personalContributionPercentage: z.number().min(0).max(100),
  ofWhichAvcPercentage: z.number().min(0).max(100),
});

export type IndividualPensionTransactionReport = z.infer<typeof individualPensionTransactionSchema>;

export const pensionReportSchema = z.object({
  transactionId: z.string().uuid(),
  benefitId: z.string().uuid(),
  benefit: benefitScopeTypeSchema,
  state: coverTransactionState,
  provider: pensionProviderEnum,
  currencyCode: currencyCodeEnum,
  organizationContribution: z.number(),
  personalContribution: z.number(),
  totalContribution: z.number(),
  ofWhichAvc: z.number(),
  individualTransactions: z.array(individualPensionTransactionSchema),
});

export type PensionReport = z.infer<typeof pensionReportSchema>;

export const lifeReportSchema = z.object({
  transactionId: z.string().uuid(),
  benefitId: z.string().uuid(),
  benefit: benefitScopeTypeSchema,
  state: coverTransactionState,
  provider: lifeProviderEnum,
  currencyCode: currencyCodeEnum,
});

export type LifeReport = z.infer<typeof lifeReportSchema>;

export const billingInformationReportSchema = z.object({
  billingDate: z.date(),
  payrollFor: z.string(),
  health: z.array(healthReportSchema),
  pension: z.array(pensionReportSchema),
  life: z.array(lifeReportSchema),
});

export type BillingInformationReport = z.infer<typeof billingInformationReportSchema>;

export const getBillingInformationReportSuccessSchema = apiResponseSuccessSchema.extend({
  data: z.array(billingInformationReportSchema),
});

export const getBillingInformationReportErrorSchema = apiResponseFailureSchemaWithErrorArray.extend({
  errors: z.array(
    z.object({
      code: getBillingInformationReportErrorEnum,
      cause: z.string().nullable(),
    }),
  ),
});
